<template>
  <div class="homepage container-fluid">

    <div class="title">
      <div class="lefttitle">
        <p class="encomname">VIEWDONE</p>
        <p>汇达智显</p>
      </div>
      <div class="righttitle">
        <span class="shopping">官方商城</span>
        <span>中|EN</span>
      </div>
    </div>

    <div class="navmenu">
      <el-col :span="12">
        <el-menu
          :collapse="true"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="rgba(255,255,255,0)"
          text-color="#fff"
        >
          <el-submenu index="1">
            <template slot="title">
              <p>产品系列 <i class="el-icon-arrow-down"></i></p>
            </template>

            <el-submenu index="1-1">
              <template slot="title">会议机</template>
              <el-menu-item
                index="1-1-1"
                v-for="item in product"
                :key="item.id"
                style=""
                >{{ item.text }}</el-menu-item
              >
            </el-submenu>
            <el-submenu index="1-2">
              <template slot="title">广告机</template>
              <el-menu-item index="1-2-1">产品A</el-menu-item>
              <el-menu-item index="1-2-2">产品B</el-menu-item>
              <el-menu-item index="1-2-3">产品C</el-menu-item>
              <el-menu-item index="1-2-4">产品D</el-menu-item>
            </el-submenu>
            <el-submenu index="1-3">
              <template slot="title">智能配件</template>
              <el-menu-item index="1-3-1">产品A</el-menu-item>
              <el-menu-item index="1-3-2">产品B</el-menu-item>
              <el-menu-item index="1-3-3">产品C</el-menu-item>
              <el-menu-item index="1-3-4">产品D</el-menu-item>
            </el-submenu>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <p>解决方案 <i class="el-icon-arrow-down"></i></p>
            </template>

            <el-menu-item index="2-1" @click="topath">
              球当当：篮球赛事智能解决方案
              <!-- <template slot="title">方案1</template> -->
              <!-- <el-menu-item index="2-1-1" @click="topath">球当当：篮球赛事智能解决方案</el-menu-item> -->
            </el-menu-item>
            <!-- <el-submenu index="2-2">
              <template slot="title">方案2</template>
            </el-submenu> -->
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <p>服务中心 <i class="el-icon-arrow-down"></i></p>
            </template>

            <el-submenu index="3-1">
              <template slot="title">服务中心1</template>
            </el-submenu>
            <el-submenu index="3-2">
              <template slot="title">服务中心2</template>
            </el-submenu>
          </el-submenu>

          <el-submenu index="4">
            <template slot="title">
              <p>合作案例 <i class="el-icon-arrow-down"></i></p>
            </template>

            <el-submenu index="4-1">
              <template slot="title">合作案例1</template>
            </el-submenu>
            <el-submenu index="4-2">
              <template slot="title">合作案例2</template>
            </el-submenu>
          </el-submenu>

          <el-submenu index="5">
            <template slot="title">
              <p>关于我们 <i class="el-icon-arrow-down"></i></p>
            </template>

            <el-submenu index="4-1">
              <template slot="title">关于我们1</template>
            </el-submenu>
            <el-submenu index="4-2">
              <template slot="title">关于我们2</template>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </el-col>
    </div>
    <div class="Brandconcept">
      <h3>品牌理念</h3>
      <h2>这是我们的品牌理念</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      isCollapse: true,

      //产品系列的会议机
      product: [
        { id: 1, text: "产品A" },
        { id: 2, text: "产品B" },
        { id: 3, text: "产品C" },
        { id: 4, text: "产品D" },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    topath() {
      this.$router.push("/qdd");
      // console.log("已进入");
    },
    // goQDD(){
    //   this.$router.push("/about");
    // }
  },
};
</script>
<style lang="less">
body {
  /* 加载背景图 */
  background-image: url(@/assets/backgroundpage.png);
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  /* 设置背景颜色，背景图加载过程中会显示背景色 */
  background-color: #464646;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 2% 10% 0 10%;
}
.lefttitle {
  color: #347caf;
  font-weight: bold;
  p {
    font-size: 35px;
  }
  font-family: "Source Han Sans CN";
  .encomname {
    margin-bottom: 5px;
  }
}
.righttitle {
  font-size: 20px;
  .shopping {
    margin-right: 20px;
  }
}

.navmenu {
  margin-top: 5%;
  margin-left: 10%;
}

.navmenu .el-col.el-col-12 .el-menu-vertical-demo.el-menu--collapse.el-menu {
  border: none;
  width: 160px;
  height: 50px;

  p {
    font-size: 25px;
  }
}
.navmenu .el-col.el-col-12 .el-submenu__title {
  margin-top: 20px;
}
.navmenu .el-col.el-col-12 .el-submenu__title :hover {
  color: #347caf !important ;
}
.el-menu-item :hover {
  color: #347caf !important ;
}
.Brandconcept {
  position: absolute;
  right: 8%;
  bottom: 20%;
  color: aliceblue;
  h3 {
    border-bottom: 4px solid white;
    width: 120px;
    padding: 5px 0;
  }
  h2 {
    padding: 5px 0;
  }
}
</style>
