<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import rem from "@/assets/rem.js";
export default {
  name: "app",
  // created() {
  //   new DevicePixelRatio().init();
  // },
  components: {
    rem,
  },
};
</script>
<style lang="less"></style>
